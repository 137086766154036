
.claim_token_parent{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
    color: #fff;
  
  }
  .claim_token{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .invites{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .claim_token_child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    padding-bottom: 15px;
  }
  .claim_token_child .left{
    display: flex;
    height: 50px;
    justify-content: flex-start;
    flex: 1;
  }
  .claim_token_child .left>li{
    display: flex;
    align-items: center;
  }
  .claim_token_child .mid{
    display: flex;
    height: 50px;
    justify-content: flex-start;
    flex: 1;
    background: #23455e;
    border: 2px solid #23455e;
    border-radius: 10px;
  }
  .claim_token_child .mid>li{
    display: flex;
    align-items: center;
    background: #23455e;
    padding-left: 10px;
    border-radius: 10px;

  }
  .claim_token_child .right{
    display: flex;
    height: 50px;
    justify-content: flex-end;
    align-items: center;
    flex: 2;
    /* border: 2px solid #23455e; */
  }
  .claim_token_child .right .claim_button{
    display: flex;
    align-items: center;
    width: 40%;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 10px;
    justify-content: center;
  }

  .claim_token_child .right .claim_button:hover{
    cursor: pointer;
    background: #23455e;
  }
  .claim_token_child .right .claim_button:hover>img{
    background: #23455e;
  }

  .claim_token_child .right .claim_button>img{
    width: 26px;
    height: 26px;

  }

  .invites_child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    font-size: 20px;
    padding-bottom: 15px;
  }
  .invites_child .left{
    display: flex;
    height: 50px;
    justify-content: flex-start;
    flex: 1;
  }
  .invites_child .left>li{
    display: flex;
    align-items: center;
  }
  .invites_child .mid{
    display: flex;
    height: 50px;
    justify-content: flex-start;
    flex: 2;
    background: #23455e;
    border: 2px solid #23455e;
    border-radius: 10px;
  }
  .invites_child .mid>li{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #23455e;
    padding-left: 10px;
    border-radius: 10px;
    
  }
  .invites_child .right{
    display: flex;
    height: 50px;
    justify-content: center;
    flex: 1;
    /* border: 2px solid #23455e; */
  }
  .invites_child .right>img{
    width: 36px;
    height: 36px;
    margin-top: 10px;
    padding-left: 40px;
  }
  .invites_child .right>img:hover{
    cursor: pointer;
  }
  
  @media screen and (max-width: 700px) {
    .claim_token_child{
      width: 85%;
    }
    .claim_token_child .right .claim_button{
      width: 85%;
    }
    .claim_token_child .right{
      flex: 1.3;
      /* border: 2px solid #23455e; */
    }
    .invites_child{
      width: 85%;
    }
    .invites_child .right>img{
      width: 20px;
      height: 20px;
      margin-top: 15px;
      padding-left: 20px;
    }


  }