.leader_info{
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}

.leader_info_child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    padding-bottom: 15px;
    border-bottom: 2px solid #23455e;
    margin-top: 50px;
}
.leader_info_title{
    display: flex;
    width: 70%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border: 2px solid #23455e;
    border-radius: 10px 10px 0px 0px/10px 10px 0px 0px;
}
.leader_info_title .title_wrap{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #23455e; */
    border-radius: 10px;
    height: 50px;
}
.leader_info_title .title_wrap:hover{
  cursor: pointer;
}
.leader_info_title .title_wrap .chose{
    border: 1px solid #fff;
    display: flex;
    width: 100%;
    justify-content: center;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    background: #23455e;
}

.leader_info_content{
  display: flex;
  overflow-y: auto;
  width: 70%;
  height: 260px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 2px solid #23455e;
  /* border: 2px solid #fff; */
}
.leader_no_info{
  display: flex;
  overflow-y: auto;
  width: 70%;
  height: 160px;
  justify-content: center;
  align-items: center;
  color: rgb(173, 173, 173);
  border: 2px solid #23455e;
}
.leader_info_content::-webkit-scrollbar {
  width: 10px;
  background-color: #062134;
}
.leader_info_content::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: #23455e;
  border-radius: 5px;
}

.leader_info_content_detail{
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #23455e;
  }

  .leader_info_content_detail>li{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* align-items: center; */
    /* border: 2px solid #23455e; */
    width: 100%;
    height: 50px;
    font-size: 20px;
  }
  .leader_earn_detail{
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #23455e;
  }
  .leader_earn_detail>li{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 50px;
    font-size: 20px;
    flex: 1;
  }
  .leader_earn_detail>ul{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 50px;
    font-size: 20px;
    flex: 2;
  }

  .price_child{
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    height: 50px;
}
.price_child .left{
    display: flex;
    justify-content: flex-start;
    color: #fff;
    width: 100%;
    height: 50px;
    align-items: center;
    font-size: 20px;
    
}
.price_child .mid{
  display: flex;
  justify-content: flex-start;
  color: #fff;
  width: 100%;
  height: 50px;
  align-items: center;
  font-size: 20px;
  background: #23455e;
  border-radius: 10px;
  padding-left: 10px;
  /* border: 2px solid #23455e; */
  
}
.price_child .space{
  display: flex;
  justify-content: center;
  width: 100%;
}
.price_child .right>img{
  width: 30px;
  height: 30px;
  /* background: #23455e; */
  /* margin-right: 20px; */
}
.price_child .right:hover>img{
  background: #23455e;
}
.price_child .right{
  display: flex;
  justify-content: center;
  color: #fff;
  height: 50px;
  align-items: center;
  width: 100%;
  font-size: 20px;
  border: 1px solid #fff;
  /* background: #23455e; */
  border-radius: 10px;
  /* border: 2px solid #23455e; */
}

.price_child .right:hover{
  border: 1px solid #fff;
  cursor: pointer;
  background: #23455e;
}

@media screen and (max-width: 700px) {
  .leader_info_title{
    width: 85%;
  }
  .leader_info_title .title_wrap>li{
    font-size: 16px;
  }
  .leader_info_content{
    width: 85%;
  }
  .leader_no_info{
    width: 85%;
  }
  .leader_info_content .leader_info_content_detail>li{
    font-size: 16px;
  }
  .leader_info_content .leader_earn_detail>li{
    font-size: 16px;
    padding-left: 5px;
  }
  .leader_info_content .leader_earn_detail>ul{
    font-size: 16px;
  }
  .leader_info_child{
    width: 85%;
  }
  .leader_info_child>p{
    font-size: 16px;
  }
  .price_child{
    width: 85%;
  }
  .price_child .left{
    font-size: 16px;
  }
  .price_child .mid{
    font-size: 16px;
  }
  .price_child .right{
    font-size: 16px;
  }
  .price_child .space{
    width: 10%;
  }
  .price_child>img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  
}
  