
.community_parent{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 50px 0px;
    color: #fff;
  
  }
  .community{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .community_child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    font-size: 20px;
    border-bottom: 3px solid #23455e;
    padding-bottom: 15px;
  }
  .community_child li{
    display: flex;
    width: 20%;
    justify-content: center;
    color: rgb(173, 173, 173);
    /* border: 1px solid #fff; */
  }
  .community_child li:hover{
    color: #fff;
    cursor: pointer;
  }
  .community_child .chose{
    color: #fff;
  }
  .community_child li>hr{
    background-color:#23455e;
  }
  @media screen and (max-width: 700px) {
    .community_child{
      width: 85%;
    }
    .community_child li{
      width: 35%;
    }
    
    .community_parent{
      padding: 30px 0px;
    }

  }