
.bridge_parent{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    /* padding: 50px 0px; */
    margin-top: 3%;
    color: #fff;
  
  }
  .bridge{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .bridge_child{
    display: flex;
    width: 50%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    /* border: 1px solid #fff; */
    font-size: 20px;
    
  }
  
  .bridge_top{
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
    /* padding-top: 2%; */
    /* border: 1px solid #fff; */
  }
  .bridge_top .bridge_title{
    display: flex;
    color: #fff;
    font-size: 22px;
    /* margin-bottom: 2%; */
  }
  .bridge_top .bridge_title>img{
    width: 26px;
    height: 26px;
  }
  .bridge_top>li{
    display: flex;
    color: rgb(173, 173, 173);
    margin-bottom: 2%;
    font-size: 17px;
  }
  .bridge_content{
    display: flex;
    height: 350px;
    width: 100%;
    /* border: 1px solid #fff; */
    flex-direction: column;
    justify-content: flex-start;
    background: #23455e;
    border-radius: 5px;
    font-size: 20px;
  }
  .bridge_chains_parent{
    display: flex;
    width: 100%;
    justify-content: center;
    background: #23455e;
    margin-top: 10px;

  }
  .bridge_chains{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
    height: 100px;
    background: #23455e;
    /* padding-top: 2px;
    padding-left: 20px;
    padding-right: 10px; */
    /* border: 1px solid #fff; */
    /* margin-top: 20px; */
  }
  .describe_chains{
    display: flex;
    height: 40px;
    width: 100%;
    justify-content: flex-start;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .describe_chains .chains_left{
    display: flex;
    /* height: 20px; */
    width: 100%;
    flex: 2;
    justify-content: flex-start;
    align-items: center;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .describe_chains .chains_mid{
    display: flex;
    width: 100%;
    flex: 0.6;
    /* height: 20px; */
    justify-content: flex-start;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .describe_chains .chains_right{
    display: flex;
    flex: 2;
    width: 100%;
    /* height: 20px; */
    justify-content: flex-start;
    align-items: center;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .chain_select{
    display: flex;
    height: 50px;
    width: 100%;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .select_left{
    display: flex;
    /* height: 20px; */
    width: 100%;
    flex: 2;
    justify-content: flex-start;
    align-items: flex-start;
    background: #062134;
    /* border: 1px solid #fff; */
    border-radius: 5px;
  }
  .bridge_select_contain{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 5px;
    /* border: 1px solid #fff; */
   
  }
  .bridge_select_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    border: 1px solid #fff;
    border-radius: 5px;
    width: 100%;
    height: 50px;
  }
  .bridge_select_top>li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    height: 50px;
    width: 100%;
    margin-left: 5%;
  }
  .bridge_select_top>img{
    margin-right: 5%;
    width: 10%;
    
  }
  .bridge_select_top>li>img{
    margin-right: 5%;
    width: 12%;
    /* height: 50%; */
  }
  
  .bridge_select_top:hover{
    cursor: pointer;
  }
  .bridge_select_contain .bridge_select_list{
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    border: 2px solid #23455e;
    /* border-radius: 10px; */
    border-radius: 0px 0px 10px 10px/0px 0px 10px 10px;
    /* background: #23455e; */
    width: 100%;
    /* padding-left: 5%; */
    /* height: 300px; */
  }
  .bridge_select_contain .bridge_select_list>li{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #23455e;
    width: 100%;
    /* padding-left: 5%; */
  }
  .bridge_select_contain .bridge_select_list>ul{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 50px;
    padding-bottom: 2%;
    padding-top: 2%;
    /* border-bottom: 1px solid #23455e; */
    width: 100%;
    border-radius: 0px 0px 10px 10px/0px 0px 10px 10px;
    /* padding-left: 5%; */
  }
  .bridge_select_contain .bridge_select_list>li:hover{
    cursor: pointer;
    background-color: #23455e;
  }
  .bridge_select_contain .bridge_select_list>li:hover>img{
    cursor: pointer;
    background-color: #23455e;
  }
  .bridge_select_contain .bridge_select_list>ul:hover{
    cursor: pointer;
    background-color: #23455e;
  }
  .bridge_select_contain .bridge_select_list>ul:hover>img{
    cursor: pointer;
    background-color: #23455e;
  }
  .bridge_select_contain .bridge_select_list>li>img{
    margin-right: 5%;
    margin-left: 5%;
    width: 10%;
    /* background-color: #23455e; */
  }
  .bridge_select_contain .bridge_select_list>ul>img{
    margin-right: 5%;
    margin-left: 5%;
    width: 10%;
    /* background-color: #23455e; */
  }
  .select_mid{
    display: flex;
    width: 100%;
    flex: 0.6;
    /* height: 20px; */
    justify-content: center;
    align-items: center;
    background: #23455e;
    margin-left: 0.5%;
    margin-right: 0.5%;
    /* border: 1px solid #fff; */
  }
  .select_mid>img{
    width: 42px;
    height: 42px;
    background: #23455e;
  }
  .select_mid>img:hover{
    cursor: pointer;

  }
  .select_right{
    display: flex;
    flex: 2;
    width: 100%;
    /* height: 20px; */
    justify-content: flex-start;
    align-items: flex-start;
    background: #062134;
    /* border: 1px solid #fff; */
    border-radius: 5px;
  }
  .bridge_balance_parent{
    display: flex;
    width: 100%;
    justify-content: center;
    background: #23455e;
  }
  .bridge_balance{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 90%;
    height: 100px;
    background: #23455e;
    border: 1px solid #062134;
    border-radius: 5px;
    margin-top: 20px;
    /* padding-top: 2px;
    padding-left: 20px;
    padding-right: 10px; */
  }
  .bridge_balance_describe{
    display: flex;
    height: 40px;
    width: 95%;
    justify-content: flex-start;
    background: #23455e;
    margin-top: 5px;
    margin-left: 10px;
    /* margin-right: 10px; */
    /* border: 1px solid #fff; */
  }
  
  .bridge_balance_describe .chains_left{
    display: flex;
    /* height: 20px; */
    width: 100%;
    flex: 3;
    justify-content: flex-start;
    align-items: center;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .bridge_balance_describe .chains_mid{
    display: flex;
    width: 100%;
    flex: 1;
    /* height: 20px; */
    justify-content: flex-start;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .bridge_balance_describe .chains_right{
    display: flex;
    flex: 1.5;
    width: 100%;
    /* height: 20px; */
    justify-content: flex-start;
    align-items: center;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .bridge_balance_input{
    display: flex;
    height: 40px;
    width: 95%;
    justify-content: flex-start;
    background: #23455e;
    margin-top: 5px;
    margin-left: 10px;
    /* margin-right: 10px; */
  }
  .bridge_balance_input .input_left{
    display: flex;
    /* height: 20px; */
    width: 100%;
    flex: 2.5;
    justify-content: flex-start;
    align-items: center;
    background: #23455e;
    /* border: 1px solid #fff; */
  }
  .bridge_balance_input .input_left>input{
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    color: #fff;
    padding-left: 10px;
    font-size: 20px;
    border-radius: 5px;
    /* border: 1px solid #fff; */
  }
  .bridge_balance_input .input_mid{
    display: flex;
    width: 100%;
    /* height: 30px; */
    flex: 1;
    justify-content: flex-start;
    align-items: flex-end;
    background: #23455e;
    /* border-radius: 5px; */
    padding-left: 10px;

    /* border: 1px solid #fff; */
  }
  .bridge_balance_input .input_mid>li{
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    border-radius: 5px;
    font-size: 18px;
    /* border: 1px solid #fff; */
  }
  .bridge_balance_input .input_mid>li:hover{
    cursor: pointer;

  }
  .bridge_balance_input .input_right{
    display: flex;
    flex: 1.5;
    width: 100%;
    /* height: 20px; */
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    /* background: #23455e; */
    /* border: 1px solid #fff; */
  }
  .bridge_balance_input .input_right>img{
    width: 26px;
    height: 26px;
    margin-right: 6px;
  }
  .bridge_button_parent{
    display: flex;
    justify-content: center;
    background: #23455e;
    margin-top: 20px;
  }
  .bridge_button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 50px;
    background: #062134;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-top: 15px;
  }
  .bridge_button>img{
    width: 30px;
    height: 30px;
  }
  .bridge_button>li>img{
    width: 30px;
    height: 30px;
  }
  .bridge_button>li{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #062134;
    border-radius: 5px;
    font-size: 20px;
  }
  .bridge_button>li:hover{
    cursor: pointer;
  }
  
.alert_con{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* border-radius: 16px; */
  z-index: 0;
}
.alert_child{
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  /* align-items: center; */
  background-color: #23455e;
  /* background-color: rgba(0, 0, 0, 0.119); */
  border-radius: 16px;
  height: 56%;
  width: 42%;
  margin: 10% auto 0;
}
.alert_title{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-radius: 16px 16px 0px 0px/16px 16px 0px 0px;
  /* border: 1px solid #fff; */
}
.alert_title>li{
  display: flex;
  justify-content: flex-start;
  color: #fff;
  font-size: 20px;
  margin-left: 20px;
  /* height: 30px; */
  /* border: 1px solid #fff; */
}
.alert_title>p{
  display: flex;
  justify-content: flex-end;
  /* background: rgba(0, 0, 0, 0.3);
  color: #fff; */
}
.alert_child .close_img{
  width: 26px;
  height: 26px;
  margin-right: 10px;
}
.alert_child .close_img:hover{
  cursor: pointer;
}

.alert_content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23455e;
  height: 230px;
  width: 100%;
  margin-top: 10px;
  /* border: 1px solid #fff; */
}
.alert_origin{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid #fff; */
  background-color: #23455e;
}
.alert_origin>li{
  background-color: #23455e;
  font-size: 20px;
  padding-top: 20px;
}
.alert_origin>img{
  background-color: #23455e;
  padding-top: 20px;
}

.alert_layerzero{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid #fff; */
  background-color: #23455e;
}
/* .alert_layerzero>img{
  background-color: #23455e;
  width: 16px;
  height: 16px;
} */
.layerzero_tx{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 80%;
  background-color: #23455e;
  /* border: 1px solid #062134; */
  border: 1px solid #fff;
  border-radius: 3px;
  color: #fff;
}
.layerzero_tx>li{
  background-color: #23455e;
  font-size: 18px;
  margin-right: 10px;
}
.layerzero_tx>img{
  background-color: #23455e;
  width: 23px;
  height: 23px;
}
.layerzero_tx:hover{
  cursor: pointer;
}
.layerzero_ngt{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23455e;
  padding-bottom: 10px;
}
.layerzero_ngt>li{
  background-color: #23455e;
  font-size: 20px;
  margin-right: 10px;
}
.layerzero_ngt>img{
  background-color: #23455e;
  height: 30px;
  width: 30px;
}
.alert_layerzero>img{
  background-color: #23455e;
  padding-bottom: 10px;
  width: 80%;
}
.alert_destination{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* border: 1px solid #fff; */
  background-color: #23455e;
}
.alert_destination>li{
  background-color: #23455e;
  font-size: 20px;
  padding-top: 20px;
}
.alert_destination>img{
  background-color: #23455e;
  padding-top: 20px;
}
.alert_tail {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23455e;
  height: 40px;
  width: 100%;
  margin-top: 5%;
}
.alert_tail>li{
  /* align-self: center; */
  align-items: center;
  background-color: #23455e;
  color: rgb(173, 173, 173);
  font-size: 16px;
  /* margin-left: 20px; */
}
.alert_tail>li>a{
  align-items: center;
  background-color: #23455e;
  color: #42f2ae;
  font-size: 16px;
  /* padding-bottom: 30px; */
  text-decoration: underline;
}
.bridge_tips_can{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23455e;
  color: #42f2ae;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bridge_tips_cannot{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23455e;
  color: #fd7474;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

  @media screen and (max-width: 700px) {
    .bridge_child{
      width: 90%;
    }
    .bridge_top>li{
      font-size: 14px;
    }
    .bridge_top .bridge_title{
      font-size: 20px;
    }
    .chains_left{
      font-size: 16px;
    }
    .chains_right{
      font-size: 16px;
    }
    .bridge_select_top>li{
      font-size: 16px;
    }
    .bridge_select_top>li>img{
      width: 20px;
      height: 20px;

    }
    .bridge_select_list>li{
      font-size: 16px;
    }
    .bridge_select_contain .bridge_select_list>li>img{
      width: 20px;
      height: 20px;
    }
    .bridge_select_contain .bridge_select_list>ul>img{
      width: 20px;
      height: 20px;
    }
    .bridge_button>li{
      font-size: 20px;
    }
    .chains_left{
      font-size: 16px;
    }
    .chains_right{
      font-size: 16px;
    }
    .input_left>input{
      font-size: 14px;
    }
    .bridge_balance_input .input_mid>li{
      font-size: 14px;
    }
    .input_right>li{
      font-size: 16px;
    }
    .alert_child{
      height: 55%;
      width: 95%;
      margin: 30% auto 0;
    }
    .alert_title>li{
      font-size: 20px;
    }
    .alert_origin>li{
      font-size: 16px;
    }
    .layerzero_ngt>li{
      font-size: 14px;
    }
    .layerzero_ngt>img{
      height: 20px;
      width: 20px;
    }
    .layerzero_tx>li{
      font-size: 14px;
      margin-right: 5px;
    }
    .layerzero_tx>img{
      width: 20px;
      height: 20px;
    }
    .alert_destination>li{
      font-size: 16px;
    }
    .alert_tail>li{
      font-size: 14px;
      margin-left: 20px;
    }
    .alert_tail>li>a{
      font-size: 14px;
      margin-left: 20px;
    }
    

  }