.mydomain_info{
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}

.mydomain_info_child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    padding-bottom: 15px;
    border-bottom: 3px solid #23455e;
}

.mydomain_info_detail{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0px;
    
  }

.mydomain_info_detail_child{
    display: flex;
    width: 70%;
    justify-content: space-between;
    /* align-items: center; */
    font-size: 20px;
    border: 1px solid #fff;
    border-radius: 10px;
    background: #23455e;
    /* align-items: center; */
  }

  .mydomain_info_detail_child li{
    display: flex;
    align-items: center;
    height: 50px;
    color: #fff;
    margin-left: 10px;
    margin-right: 10px;
    /* padding-top: 10px; */
    font-size: 20px;
    background: #23455e;
  }

  @media screen and (max-width: 700px) {
    .mydomain_info_child{
      width: 85%;
    }
    .mydomain_info_child>p{
      font-size: 16px;
    }
    .mydomain_info_detail_child{
      width: 85%;
    }
    .mydomain_info_detail_child li{
      font-size: 16px;
      margin-left: 5px;
      margin-right: 5px;

    }

  }
  