.stake_token_parent{
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0px;
    color: #fff;
  
  }
  .stake_token{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .stake_info{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .stake_token_child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    padding-bottom: 15px;
  }
  .stake_token_contain{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid #fff; */
    flex: 1.5;
  }
  .stake_token_child .stake_token_contain .left{
    display: flex;
    height: 60px;
    justify-content: center;
    align-items: center;
    border: 1px solid #23455e;
    border-radius: 10px;
    padding-right: 20px;
    padding-left: 10px;
    width: 93%;
  }
  .stake_token_contain .left_amount{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    color: rgb(173, 173, 173);
    padding-right: 20px;
    /* border: 1px solid #fff; */
  }

  .stake_token_contain .left_amount li{
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
  }

  .stake_token_child .mid{
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    flex: 1;
    /* width: 50%; */
     border: 1px solid #23455e;
    border-radius: 10px;
    /* padding-left: 10px; */
    padding-right: 10px;
    margin-left: 20px;
    /* margin-right: 10px; */
  }
  .stake_token_child .mid>select{
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
    /* padding-left: 10px;
    padding-right: 10px;*/
    margin-left: 10px; 
    color: #fff;
    font-size: 20px;
    width: 100%;
    border: none;
    outline: none;
  }


  .stake_token_child .left>input{
    display: flex;
    align-items: center;
    padding-left: 10px;
    height: 50px;
    color: #fff;
    border: none;
    outline: none;
    /* border: 1px solid #23455e; */
    font-size: 20px;
    /* flex: 2; */
  }
  .stake_token_child .left>li{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #23455e;
    /* border: 1px solid #fff; */
    background: #23455e;
    /* margin-left: 5px;
    margin-right: 5px; */
    padding-right: 5px;
    height: 70%;
    width: 100%;
    border-radius: 10px;
  }
  .stake_token_child .left>li>img{
    width: 36px;
    height: 36px;
    background: #23455e;
    padding-right: 5px;
  }
  
  .stake_token_child .right{
    display: flex;
    height: 50px;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    width: 100%;
    /* border: 2px solid #23455e; */
  }
  .stake_token_child .right>li{
    display: flex;
    align-items: center;
    width: 85%;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 10px;
    justify-content: center;
  }

  .stake_token_child .right>li:hover{
    cursor: pointer;
    background: #23455e;
  }
  .stake_token_child .right>img{
    /* width: 16%; */
    width: 26px;
    height: 26px;
  }

  /* .stake_amount_child{
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    padding-bottom: 15px;
  } */

  
  
  .stake_info_child{
    display: flex;
    width: 70%;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    background: #23455e;
    border: 1px solid #23455e;
    border-radius: 10px;
    flex-direction: column;
    padding-bottom: 5px;
    /* opacity: 0.4; */
  }
  .stake_info_child .info_title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #122f45;
    /* border-radius: 10px; */
    border-radius: 10px 10px 0px 0px/10px 10px 0px 0px;
    /* padding-left: 5px; */
    /* opacity: 0.4; */
    /* background: #23455e; */
  }

  .stake_info_child .info_title>li{
    display: flex;
    justify-content: center;
    background: #122f45;
    /* border: 1px solid #fff; */
    /* border-radius: 10px; */
    color: rgb(173, 173, 173);
    /* border-bottom: 1px solid #23455e; */
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
    margin-left: 2px;
    flex: 1;
  }
  .stake_info_child .info_title>ul{
    flex: 2;

  }
  .stake_info_child .info_title>li>img{
    background: #122f45;
    width: 20%;
    height: 20%;
  }
  .stake_info_child .info_title .tips{
    position : absolute;
    border: 1px solid #fff;
    border-radius: 10px;
    background: #23455e;
    color: rgb(161, 161, 161);
    font-size: 16px;
    top: 45%;
    left: 63.5%;
    width: 19%;
    height: 22%;
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 0.2%;
  }
  .stake_info_child .info_content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 50px; */
    /* background: #122f45; */
    /* border-radius: 10px; */
    background: #23455e;
    padding-bottom: 5px;
    padding-top: 5px;
    border: 1px solid #122f45;
  }

  .stake_info_child .info_content .rewards{
    color: #42f2ae;
  }

  .stake_info_child .info_content>li{
    display: flex;
    justify-content: center;
    color: #fff;
    background: #23455e;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 18px;
    flex: 1;
    /* border-radius: 10px; */
    /* border: 1px solid #fff; */
    /* opacity: 1; */
  }
  
  .stake_info_child .info_content>ul{
    display: flex;
    justify-content: center;
    /* color: #fff; */
    /* background: #23455e; */
    background: #23455e;
    /* width: 30%; */
    /* padding-bottom: 15px;
    padding-top: 15px; */
    /* font-size: 18px; */
    flex: 2;
    /* border: 1px solid #fff;
    border-radius: 10px; */
  }

  .stake_info_child .info_content>ul>li{
    display: flex;
    justify-content: center;
    color: #fff;
    /* background: #23455e; */
    background: #122f45;
    width: 80%;
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 18px;
    border: 1px solid #fff;
    border-radius: 10px;
    /* border: 1px solid #fff; */
    /* opacity: 1; */
  }
  .stake_info_child .info_content>ul .claimed{
    display: flex;
    justify-content: center;
    color: rgb(173, 173, 173);
    /* background: #23455e; */
    background: #23455e;
    width: 80%;
    padding-bottom: 15px;
    padding-top: 15px;
    font-size: 18px;
    /* border: 1px solid #fff; */
    /* opacity: 1; */
  }
  .stake_info_child .info_content>ul>img{
    background: #23455e;
    width: 15%;
  }

  .stake_info_child .info_content>ul>li:hover{
    cursor: pointer;
    background: #23455e;
    
  }

  @media screen and (max-width: 700px) {
    *{
      font-size: 16px;
    }
    .stake_token_child{
      width: 85%;
      flex-direction: column;
    }
    .stake_token_child .stake_token_contain .left{
      width: 90%;
    }
    .stake_token_child .stake_token_contain .left>input{
      font-size: 16px;
      width: 90%;
    }
    .stake_token_child .stake_token_contain .left>li{
      font-size: 16px;
      width: 50%;
    }
    .stake_token_child .stake_token_contain .left>li>img{
      width: 20px;
      height: 20px;
    }
    .stake_token_contain .left_amount>li{
      font-size: 16px;
    }
    .stake_token_child .mid{
      width: 95%;
      margin-left: 0;
      padding-left: 0;
      margin-top: 10px;
    }
    .stake_token_child .mid>select{
      width: 80%;
      font-size: 16px;
    }
    .stake_token_child .right{
      margin-top: 10px;
      justify-content: center;
    }
    .stake_token_child .right>li{
      width: 96%;
      height: 50px;
      border-radius: 20px;
    }
    .stake_info_child{
      width: 85%;
    }
    .stake_info_child .info_title>li{
      font-size: 16px;
    }
    .stake_info_child .info_content>li{
      font-size: 16px;
    }
    .stake_info_child .info_title>ul{
      flex: 1.6;
    }
    .stake_info_child .info_content>ul{
      flex: 1.6;
    }
    .stake_info_child .info_content>ul .claimed{
      font-size: 16px;
    }
    .stake_info_child .info_content>ul>li{
      font-size: 16px;
    }
    .stake_info_child .info_content>ul>img{
      width: 45%;
    }
    .stake_token_child .right>img{
      /* width: 10%; */
      width: 26px;
      height: 26px;
    }
    .stake_info_child .info_title .tips{
      position : absolute;
      border: 1px solid #fff;
      border-radius: 10px;
      background: #23455e;
      color: rgb(161, 161, 161);
      font-size: 10px;
      top: 47%;
      left: 35%;
      width: 49%;
      height: 17%;
    }
    .stake_info_child .info_title>li>img{
      width: 40%;
      height: 40%;
    }


  }
