.domain_notfind_info{
    display: flex;
    justify-content: center;
    color: #fd7474;
    font-size: 20px;
}

.domain_notfind_info_input{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px 0px;
  }

.domain_notfind_info_input_child{
    display: flex;
    width: 70%;
    justify-content: space-between;
    font-size: 20px;
    border: 2px solid #fff;
    border-radius: 10px;
    /* align-items: center; */
  }

  .domain_notfind_info_input_child li{
    height: 50px;
    color: #fd7474;
     margin-left: 10px;
    margin-right: 10px;
    /*padding-top: 10px; */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
  }

  @media screen and (max-width: 700px) {
    .domain_notfind_info_input_child{
      width: 85%;
    }
    .domain_notfind_info_input_child>li{
      font-size: 16px;
    }
  }
  