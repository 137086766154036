.domain_find_info{
    display: flex;
    justify-content: center;
    color: #42f2ae;
    font-size: 20px;
}

.domain_find_info_input{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 30px 0px;
  }

.domain_find_info_input_child{
    display: flex;
    width: 70%;
    justify-content: space-between;
    /* align-items: center; */
    font-size: 20px;
    border: 1px solid #fff;
    border-radius: 10px;
    /* align-items: center; */
  }

  .domain_find_info_input_child li{
    display: flex;
    align-items: center;
    height: 50px;
    color: #42f2ae;
    margin-left: 10px;
    margin-right: 10px;
    /* padding-top: 10px; */
    font-size: 20px;
  }
  @media screen and (max-width: 700px) {
    .domain_find_info_input_child{
      width: 85%;
    }
    .domain_find_info_input_child>li{
      font-size: 16px;
    }
  }
  