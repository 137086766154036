.parent{
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 20px;
    /* margin-bottom: 10px; */
}

.child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    padding-bottom: 5px;
    /* border-bottom: 3px solid #23455e; */
}

.info{
    display: flex;
    width: 70%;
    justify-content: space-between;
    padding: 20px 0px;
    
  }

 .info_left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    border: 2px solid #23455e;
    border-radius: 10px;
    flex: 1;
    margin-right: 20px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    height: 120px;
    /* align-items: center; */
  }
  .info_left .info_num{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 20px;

  }
  .info_num >img{
    width: 48px;
    height: 48px;
    margin-right: 20px;
    margin-left: 20px;
    /* flex: 1; */
  } 
  .info_num >img:hover{
    cursor: pointer;
  } 
  .info_num >li{
    height: 50px;
    text-align: center;
    border: 2px solid #23455e;
    border-radius: 10px;
    font-size: 30px;
    /* padding-top: 10px; */
  }
  
  .info_left li{
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
    /* border: 2px solid #23455e; */
  } 

  .info_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    flex: 2;
    font-size: 20px;
    margin-left: 10px;
  }

  .info_right .child1{
    display: flex;
    width: 100%;
    justify-content: center;
    border-bottom: 3px solid #23455e;
    padding-bottom: 15px;
  }

  .info_right .child2{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 15px;
  }

  .register{
    display: flex;
    width: 70%;
    height: 45px;
    margin-top: 15px;
    /* margin-top: 15px;
    padding-top: 10px; */
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #fff;
  }
  .register:hover{
    cursor: pointer;
    background: #23455e;
  }
  
  .register2{
    display: flex;
    width: 70%;
    height: 45px;
    margin-top: 15px;
    /* padding-top: 10px; */
    justify-content: center;
    align-items: center;
    /* align-items: center; */
    border-radius: 20px;
    border: 1px solid #fff;
  }
  .register2>img{
    width: 26px;
    height: 26px;
  }

  @media screen and (max-width: 700px) {
    .child{
      width: 85%;
     }
    .info{
      display: flex;
      width: 90%;
      justify-content: space-between;
    }
    .info .info_left{
      flex: 1;
      width: 20%;
      /* border: 1px solid #fff; */
    }
    .info .info_right{
      flex: 1;
      width: 100%;
      /* border: 1px solid #fff; */
    }
    .info_num >img{
      width: 32px;
      height: 32px;
      margin-right: 10px;
      margin-left: 10px;
      /* flex: 1; */
    } 
    .info_num >li{
      height: 30px;
      text-align: center;
      border: 2px solid #23455e;
      border-radius: 10px;
      font-size: 16px;
      /* padding-top: 10px; */
    }
    .register{
      display: flex;
      align-items: center;
      width: 85%;
    }
    .register2{
      display: flex;
      align-items: center;
      width: 85%;
    }

  }
  