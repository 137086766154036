* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  background: #062134;
}


nav {
  box-sizing: border-box;
  width: 100%;
  padding: 1% 5%;
  background: #062134;
  display: flex;
  border-bottom: 2px solid #23455e;
  /* position: fixed; */
  /* box-shadow: 0px 1px 2px #fff; */
}

.domain_info_parent{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 30px 0px;
  color: #fff;
  margin-bottom: 20px;
}
.domain_info{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
}
.promotion_game{
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.promotion_game_child{
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;
  font-size: 20px;
  border: 2px solid #23455e;
  border-radius: 10px;
}
.promotion_game_child:hover{
  cursor: pointer;
}

/* .promotion_game_child>img{
  width: 90px;
  height: 90px;
} */
.promotion_img{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-left: 10px;
}
.promotion_img>img{
  width: 160px;
  height: 160px;
  /* margin-left: 10px; */
  /* border: 2px solid #23455e; */
}
.promotion_title{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 10px;
  /* border: 2px solid #23455e; */
}
.promotion_title>li{
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px; 
  color: rgb(173, 173, 173);
}

.promotion_title>li>img{
  width: 100px;
  height: 30px;
  margin-left: 10px;
  color: #23455e;
  /* margin-top: 10px; */
  /* border: 1px solid #23455e; */
}
.promotion_title_child{
  margin-top: 10px;
}
.domain_info_child{
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
}
.domain_info_child>li{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.domain_info_child>li>img{
  height: 36px;
  width: 36px;
  margin-right: 10px;
}

.domain_info_input{
  display: flex;
  width: 100%;
  justify-content: center;
  /* border: 2px solid #fff; */
  padding: 30px 0px;
}
.domain_info_input_child{
  display: flex;
  width: 70%;
  justify-content: center;
  font-size: 20px;
  height: 50px;
  border: 2px solid #23455e;
  border-radius: 10px;
  /* color: #fff; */
}
.domain_info_input_child input{
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  padding-left: 10px;
  font-size: 20px;
  border-radius: 10px;
  flex: 5;
}
.domain_info_input_child>img{
  height: 40px;
  margin-top: 5px;
  margin-right: 10px; 
  /* flex: 1; */
}
.domain_info_input_child>img:hover{
  cursor: pointer;
}

/* 
nav a {
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

nav>a>img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  
}

nav>li{
  display: flex;
  justify-content: center;
  align-items: center;
}

nav>li .discord {
  width: 36px;
  height: 36px;
  margin-left: 30px;
  margin-top: 5px;
 
} */


.nav-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  /* margin-right: 5%; */
  /* border: 1px solid #fff; */
  flex: 1;
  /* background-color: darkcyan; */
} 

.nav-left a {
  text-decoration: none;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* border: 1px solid #fff; */
  flex: 3;
}
.nav-left>a>img {
  width: 36px;
  height: 36px;
  margin-right: 2%;
  
}

.nav-left>li{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* border: 1px solid #fff; */
  flex: 0.8;
}

.nav-left>li .discord {
  width: 36px;
  height: 36px;
  /* margin-left: 30px;*/
  margin-top: 5px; 
}


.nav-right {
  flex: 2.3;
  /* background-color: darkgreen; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* border: 1px solid #fff; */
}

.nav-right ul {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1.3;
}

.nav-right ul li {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid #fff; */
  width: 40%;
  /* display: inline-block; */
  /* padding: 0 30px; */
  color: rgb(173, 173, 173);
}
.nav-right ul li>img {
  width: 26px;
  height: 26px;
}

.nav-right ul li:hover {
  color: rgb(228, 228, 228);
  cursor: pointer;
}

div.nav-right ul .chose {
  color: #fff;
}

.select-box {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* border: 1px solid #fff; */
}

/* .select-box select {
  display: flex;
  width: 300px;
  height: 40px;
  outline: none;
  border: none;
  font-size: 20px;
  padding: 0 0px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 10px;
  color: #fff;
  border: 1px solid rgb(173, 173, 173);
  /* background: darkblue; */
/* }  */
/* .select-box select>option{
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
} */

.select-box button {
  border: 1px solid #fff;
  background: none;
  /* width: 180px; */
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
  
  /* flex: 1; */
}


.select-box .select_contain{
  /* display: flex;
  justify-content: center; */
  /* align-items: flex-start; */
  font-size: 20px;
  /* border: 2px solid #23455e; */
  border-radius: 10px;
  /* border: 1px solid #fff; */
  /* background: #23455e; */
  /* width: 200px; */
  width: 100%;
  height: 50px;
  color: #fff;
  flex: 1.5;
}
.select-box .select_wallet{
  /* display: flex;
  justify-content: center; */
  /* align-items: flex-start; */
  font-size: 20px;
  /* border: 2px solid #23455e; */
  border-radius: 10px;
  /* border: 1px solid #fff; */
  /* background: #23455e; */
  /* width: 200px; */
  width: 100%;
  height: 50px;
  color: #fff;
  flex: 1.5;
  margin-left: 1%;
}

.disconnectComponent{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #fff;
}
.disconnectComponent:hover {
  background-color: #23455e;
  color: #fff;
  cursor: pointer;
}
.disconnectComponent:hover>li {
  background-color: #23455e;
  color: #fff;
  cursor: pointer;
}


.select-box .select_top{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  width: 100%;
  height: 50px;
}
.select-box .select_top>li{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  margin-left: 5%;
}
.select-box .select_top>img{
  margin-right: 5%;
  width: 10%;
  
}
.select-box .select_top>li>img{
  margin-right: 5%;
  width: 12%;
  /* height: 50%; */
}

.select-box .select_top:hover{
  cursor: pointer;
}
.select-box .select_list{
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  border: 2px solid #23455e;
  /* border-radius: 10px; */
  border-radius: 0px 0px 10px 10px/0px 0px 10px 10px;
  /* background: #23455e; */
  width: 100%;
  /* padding-left: 5%; */
  /* height: 300px; */
}
.select-box .select_list>li{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #23455e;
  width: 100%;
  /* padding-left: 5%; */
}
.select-box .select_list>ul{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding-bottom: 2%;
  padding-top: 2%;
  /* border-bottom: 1px solid #23455e; */
  width: 100%;
  border-radius: 0px 0px 10px 10px/0px 0px 10px 10px;
  /* padding-left: 5%; */
}
.select-box .select_list>li:hover{
  cursor: pointer;
  background-color: #23455e;
}
.select-box .select_list>li:hover>img{
  cursor: pointer;
  background-color: #23455e;
}
.select-box .select_list>ul:hover{
  cursor: pointer;
  background-color: #23455e;
}
.select-box .select_list>ul:hover>img{
  cursor: pointer;
  background-color: #23455e;
}
.select-box .select_list>li>img{
  margin-right: 5%;
  margin-left: 5%;
  width: 10%;
  /* background-color: #23455e; */
}
.select-box .select_list>ul>img{
  margin-right: 5%;
  margin-left: 5%;
  width: 10%;
  /* background-color: #23455e; */
}
/* 
.alert_con{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}
.alert_child{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 16px;
  height: 50%;
  width: 60%;
  margin: 10% auto 0;
} */
/* .alert_child p{
  display: flex;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
} */
/* .alert_child p>img{
  width: 26px;
  height: 26px;
}
.alert_child p>img:hover{
  cursor: pointer;
}

.alert_child li{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #23455e;
  border: 2px solid #23455e;
  border-radius: 10px;
  width: 80%;
  height: 20%;
  color: #fff;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 10%;
  font-size: 20px;
}
.alert_child li>img{
  width: 48px;
  height: 48px;
  background-color: #23455e;
  margin-right: 5%;

}
.alert_child li:hover{
  cursor: pointer;

}  */

/* .select-box li {
  border: 1px solid #fff;
  background: none;
  width: 180px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  border-radius: 10px;
} */

.select-box button:hover {
  background-color: #23455e;
  color: #fff;
  cursor: pointer;
}

label[for="nav-checkbox"] {
  display: none;
}

#nav-checkbox {
  width: 0;
  height: 0;
}

@media screen and (max-width: 700px) {
  * {
    font-size: 16px;
  }
  nav {
      flex-wrap: wrap;
      justify-content: space-between;
  }

  label[for="nav-checkbox"] {
      display: initial;
      /* background-color: darkmagenta; */
      width: 40px;
      height: 30px;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: rgb(173, 173, 173) 2px solid;
      color: rgb(173, 173, 173);
      border-radius: 4px;
      padding-bottom: 4px;
  }

  label[for="nav-checkbox"]:hover {
      color: #fff;
      border: #fff 2px solid;
  }
  

  #nav-checkbox:checked + label {
      background-color: #fff;
      color: #000;
      border: #fff 2px solid;
  }

  .nav-left {
      margin: 0;
  }

  .nav-right {
      display: none;
      flex: 0 0 100%;
      width: 100%;
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
  }

  .nav-right>ul {
      flex-direction: column;
  }
  .nav-right>ul>li {
    font-size: 16px;
    margin-top: 5px;
}

  .nav-right ul li a {
      /* background-color: rgb(153, 57, 57); */
      width: 100%;
      padding: 10px 0;
  }

  #nav-checkbox:checked ~ .nav-right {
      display: flex;
  }
  .select-box {
    flex-direction: column;
    align-items: initial;
    padding: 10px 0;
  }
  
  .select-box .select_wallet{
    width: 100%;
    margin-left: 0;

  }
  .select-box .select_wallet button {
    width: 100%;
    font-size: 16px;
    /* margin: 0 0 10px 0; */
    margin-top: 5px;
  }
  .domain_info_child{
    width: 85%;
  }
  .promotion_game_child{
    width: 85%;
  }
  .domain_info_input_child{
    width: 85%;
  }
  .domain_info_input_child>img{
    height: 40px;
  }
  .domain_info_input_child input{
    padding-left: 20px;
    font-size: 16px;
  }
  .domain_info_parent{
    padding: 30px 0px;
  }
  .promotion_title{
    border: 2px solid #23455e;
    border-radius: 10px;    
  }
  .promotion_title>li{
    font-size: 15px;
  }

  .promotion_game_child{
    flex-direction: column;
    border: none;
  }
}

@media screen and (max-width: 380px) {
  .select-box {
      flex-direction: column;
      align-items: initial;
  }
  
  .select-box select {
      width: auto;
      margin: 0 0 10px 0;
  }
  
  .select-box .select_wallet{
    width: 100%;

  }
  .select-box .select_wallet button {
    width: 100%;
    margin: 0 0 10px 0;
  }
}