.game_parent{
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    align-items: center;
    flex-direction: column;
    margin-top: 1%;
    /* padding: 50px 0px; */
    /* border: 1px solid #fff; */
  }
  .game_parent_item{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70%;
    flex-direction: column;
    /* border: 1px solid #fff; */
  }
.game_info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    /* border: 1px solid #fff; */
  }
.launch_app{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    width: 100%;
  }
  .launch_app>li{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 25%;
    height: 38px;
    border: 1px solid #fff;
    border-radius: 10px;
    background: #23455e;
  }
  .launch_app>li:hover{
    cursor: pointer;
  }
  .launch_app>li>a{
    background: #23455e;
    color: #fff;
    /* font-size: 18px; */
  }
.game_info .game_title{
    display: flex;
    color: #fff;
    font-size: 20px;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1%;
    
}
.game_info .game_title>img{
    width: 28px;
    height: 28px;
}
.game_info .game_title_tip{
    color: rgb(173, 173, 173);
    font-size: 16px;
    display: flex;
    align-items: center;
}
.game_info .game_content{
    display: flex;
    color: rgb(173, 173, 173);
    font-size: 16px;
    text-align: justify;
    margin-top: 1%;
}
.game_info .game_content_more{
    display: flex;
    color: rgb(173, 173, 173);
    font-size: 16px;
    text-align: justify;
    text-decoration: underline;
    align-items: center;
    justify-content: flex-start;
}
.game_info .game_content_more:hover{
    cursor: pointer;
  } 
  
.game_info .game_content_more>img{
    width: 22px;
    height: 22px;
    /* margin-left: -10px; */
}
.game_content_up{
    width: 16px;
    height: 16px;
}
.game_content_up:hover{
    cursor: pointer;
}
.game_info .game_content_detail{
    display: flex;
    color: rgb(173, 173, 173);
    font-size: 16px;
    text-align: justify;
    display: none;
    /* margin-top: 1%; */

}
.game_item{
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: row;
}
.game_child{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    margin-top: 1%;
}
.game_left{
    display: flex;
    color: #fff;
    font-size: 20px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.game_right{
    display: flex;
    color: #fff;
    font-size: 20px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
.game_info_chick{
    display: flex;
    color: #fff;
    font-size: 20px;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: -15%; */
    margin-bottom: 10%;
    border: 1px solid #fff;
    border-radius: 10px;
    background: #23455e;
}
.chick_check{
    display: flex;
    color: #fff;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    background: #23455e;
    border-bottom: 2px solid #062134;
}

.chick_check>li{
    display: flex;
    color: #fff;
    width: 80%;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* background: #23455e; */
    /* margin-bottom: 5px; */
    border: 1px solid #fff;
    background: #23455e;
    border-radius: 20px;
    margin-bottom: 2%;
}
.chick_check>li:hover{
    cursor: pointer;
}
.chick_check>li>img{
    width: 26px;
    height: 26px;
    background: #23455e;
}
/* .chick_mint{
    display: flex;
    color: #fff;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: 10px;
    margin-top: 10px;
} */
.chick_mint_title{
    display: flex;
    color: #fff;
    width: 100%;
    height: 60px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    /* margin-top: 2%; */
    border-bottom: 2px solid #062134;
    background: #23455e;
}
.chick_mint_title_left{
    display: flex;
    color: #fff;
    width: 100%;
    font-size: 20px;
    padding-left: 10%;
    background: #23455e;
    /* border: 1px solid #fff; */
}
.chick_mint_title_right{
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    padding-right: 10%;
    background: #23455e;
    /* border: 1px solid #fff; */
}
.chick_mint_price{
    display: flex;
    color: #fff;
    width: 100%;
    height: 80px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #062134;
    background: #23455e;
}
.chick_mint_price_left{
    display: flex;
    color: #fff;
    width: 100%;
    font-size: 20px;
    padding-left: 7%;
    background: #23455e;
    /* border: 1px solid #fff; */
}
.chick_mint_price_left >img{
    width: 28px;
    height: 28px;
    margin-right: 6%;
    margin-left: 6%;
    background: #23455e;
    /* flex: 1; */
  } 
  .chick_mint_price_left >img:hover{
    cursor: pointer;
  } 
  .chick_mint_price_left >li{
    height: 30px;
    width: 30%;
    text-align: center;
    border: 2px solid #23455e;
    border-radius: 10px;
    font-size: 20px;
    /* padding-top: 10px; */
  }
.chick_mint_price_right{
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: flex-end;
    font-size: 18px;
    padding-right: 12%;
    background: #23455e;
    /* margin-left: 30%; */

    /* border: 1px solid #fff; */
}
.chick_mint_price_right>img{
    width: 26px;
    height: 26px;
    margin-left: 1%;
    margin-right: 1%;
    background: #23455e;
}
.chick_mint_do{
    display: flex;
    color: #fff;
    width: 80%;
    height: 80px;
    align-items: center;
    justify-content: center;
    background: #23455e;
    /* border: 1px solid #fff; */
}
.chick_mint_do>li{
    display: flex;
    color: #42f2ae;
    width: 100%;
    height: 60%;
    align-items: center;
    justify-content: center;
    background: #23455e;
    border: 1px solid #fff;
    border-radius: 20px;
    /* pointer-events: none; */
}
.chick_mint_do>li:hover{
    cursor: pointer;
}
.chick_mint_do>li>img{
    width: 26px;
    height: 26px;
    background: #23455e;
}
.game_info_egg{
    display: flex;
    color: #fff;
    font-size: 20px;
    width: 80%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: -15%; */
    margin-bottom: 10%;
    background: #23455e;
    border: 1px solid #fff;
    border-radius: 10px;
    z-index: 2;
}
.egg_mint_title{
    display: flex;
    color: #fff;
    font-size: 20px;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 2%;
    background: #23455e;
    border-bottom: 2px solid #062134;
}
.egg_mint_title_left{
    display: flex;
    color: #fff;
    width: 100%;
    font-size: 20px;
    padding-left: 10%;
    background: #23455e;
    margin-bottom: 2%;
    /* border: 1px solid #fff; */
}
.egg_mint_title_right{
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: flex-end;
    font-size: 20px;
    padding-right: 10%;
    background: #23455e;
    margin-bottom: 2%;
    /* border: 1px solid #fff; */
}
.egg_mint_price{
    display: flex;
    color: #fff;
    width: 100%;
    height: 80px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: #23455e;
    border-bottom: 2px solid #062134;
}
.egg_mint_price_left{
    display: flex;
    color: #fff;
    width: 100%;
    font-size: 20px;
    padding-left: 7%;
    background: #23455e;
    /* border: 1px solid #fff; */
}
.egg_mint_price_left >img{
    width: 28px;
    height: 28px;
    margin-right: 6%;
    margin-left: 6%;
    background: #23455e;
    /* flex: 1; */
  } 
  .egg_mint_price_left >img:hover{
    cursor: pointer;
  } 
  .egg_mint_price_left >li{
    height: 30px;
    width: 30%;
    text-align: center;
    /* background: #23455e; */
    border: 2px solid #23455e;
    border-radius: 10px;
    font-size: 20px;
    /* margin-bottom: 10%; */
    /* padding-top: 10px; */
  }
.egg_mint_price_right{
    display: flex;
    color: #fff;
    width: 100%;
    justify-content: flex-end;
    font-size: 18px;
    padding-right: 10%;
    background: #23455e;
    /* border: 1px solid #fff; */
}
.egg_mint_price_right>img{
    width: 26px;
    height: 26px;
    margin-left: 1%;
    margin-right: 1%;
    background: #23455e;
}
.egg_mint_num{
    display: flex;
    color: #fff;
    width: 100%;
    height: 60px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: #23455e;
    border-bottom: 2px solid #062134;
}
.egg_mint_num>li{
    display: flex;
    color: #fff;
    width: 100%;
    font-size: 18px;
    padding-left: 10%;
    background: #23455e;
    /* border: 1px solid #fff; */
}

.egg_mint_do{
    display: flex;
    color: #fff;
    width: 80%;
    height: 80px;
    align-items: center;
    justify-content: center;
    background: #23455e;
    /* border: 1px solid #fff; */
}
.egg_mint_do>li{
    display: flex;
    color: #42f2ae;
    width: 100%;
    height: 60%;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 20px;
    background: #23455e;
}
.egg_mint_do>li:hover{
    cursor: pointer;
}
.egg_mint_do>li>img{
    width: 26px;
    height: 26px;
    background: #23455e;
}

@media screen and (max-width: 700px) {
    .game_parent{
        margin-top: 8%;
    }
    .game_item{
        flex-direction: column;
    }
    .game_parent_item{
        width: 90%;
      }
    .game_info_chick{
        width: 100%;
        margin-top: -30%;
        margin-bottom: -5%;
        z-index: 2;
    }
    .game_info_egg{
        width: 100%;
        margin-top: -35%;
    }
    /* .game_left{

    } */
    .game_left{
        margin-bottom: -10%;
        /* margin-top: -10%; */
    }
    .game_right{
        margin-top: -20%;
        /* z-index: -1; */
        /* border: 1px solid #fff; */
    }
    .launch_app>li{
        width: 50%;
    }
    .game_info{
        width: 100%;
    }
    .chick_mint_title>li{
        font-size: 16px;
    }
    .chick_mint_price_right{
        font-size: 16px;
    }
    .egg_mint_title>li{
        font-size: 16px;
    }
    .egg_mint_price_right{
        font-size: 16px;
    }
    .egg_mint_num>li{
        font-size: 16px;
    }
  }