.myinvites_info{
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}

.myinvites_info_child{
    display: flex;
    width: 70%;
    justify-content: flex-start;
    padding-bottom: 15px;
    border-bottom: 2px solid #23455e;
}
.myinvites_info_title{
    display: flex;
    width: 70%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border: 2px solid #23455e;
    border-radius: 10px;
}
.myinvites_info_title .title_wrap{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* border: 2px solid #23455e; */
    border-radius: 10px;
    height: 50px;
}
.myinvites_info_title .title_wrap:hover{
  cursor: pointer;
}
.myinvites_info_title .title_wrap .chose{
    border: 1px solid #fff;
    display: flex;
    width: 100%;
    justify-content: center;
    border-radius: 10px;
    height: 50px;
    align-items: center;
    background: #23455e;
}


.myinvites_info_content{
  display: flex;
  overflow-y: auto;
  width: 70%;
  height: 260px;
  /* height: 50px; */
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  border: 2px solid #23455e;
}
.myinvites_no_info{
  display: flex;
  overflow-y: auto;
  width: 70%;
  height: 160px;
  /* height: 50px; */
  justify-content: center;
  align-items: center;
  color: rgb(173, 173, 173);
  border: 2px solid #23455e;
}
.myinvites_info_content::-webkit-scrollbar {
  width: 10px;
  background-color: #062134;
}
.myinvites_info_content::-webkit-scrollbar-thumb {
  width: 10px;
  background-color: #23455e;
  border-radius: 5px;
}

.myinvites_info_content_detail{
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #23455e;
  }

  .myinvites_info_content_detail>li{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    /* border: 2px solid #23455e; */
    width: 100%;
    height: 50px;
    font-size: 20px;
  }
  
  .myinvites_earn_detail{
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #23455e;
  }
  .myinvites_earn_detail>li{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 50px;
    font-size: 20px;
    flex: 1;
  }
  .myinvites_earn_detail>ul{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    width: 100%;
    height: 50px;
    font-size: 20px;
    flex: 2;
  }

  @media screen and (max-width: 700px) {
    .myinvites_info_child{
      width: 85%;
    }
    .myinvites_info_title{
      width: 85%;
    }
    .myinvites_info_content{
      width: 85%;
    }
    .myinvites_no_info{
      width: 85%;
    }
    .myinvites_info_content_detail>li{
      font-size: 16px;
    }
    .myinvites_earn_detail>li{
      font-size: 16px;
      padding-left: 5px;
    }
    .myinvites_earn_detail>ul{
      font-size: 16px;
    }
    /* .myinvites_earn_detail{
      padding-left: 5px;
    } */

  }
  